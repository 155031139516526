import WebServiceRequest from '../Api/WebServiceRequest'

class OrderChangeStatusRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
  }

  setId(data) {
    super.setTag('Order/ChangeOrderStatus?orderId=' + data.orderId + '&status=' + data.status)
  }
}

class OrderCreateByAdmin extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Order/CreateByAdmin')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class OrderCreateRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Order/Create')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class OrderCreateForAdminRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Order/CreateForAdmin')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class OrderDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Orders/DeleteOrder')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class OrderGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Orders/GetOrders')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class OrderGetAllForUserRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Orders/GetOrders')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class OrderGetByUserIdRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Orders/GetOrdersByUserId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class OrderGetAllForBoostersRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Orders/GetOrdersByBoosterId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class OrderGetProductsForStudents extends WebServiceRequest {
  constructor(context) {
    super(context)
  }

  setParams(data) {
    super.setTag('Order/GetProductsForStudents?searchCommand=' + data.searchCommand + '&pageNumber=' + data.pageNumber + '&count=' + data.count)
  }
}

class OrderGetRegisteredUserRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Order/GetRegisteredUser')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class OrderGetRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Orders/GetOrderBy')

  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class OrderUpdateRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Orders/UpdateOrder')
  }

  setPayload(data) {
    super.setRequestParamDataObj(data)
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class OrderCancelByAdminRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Orders/CancelOrderByAdmin')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class ChangeOrderBooster extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Orders/ChangeOrderBoosterByAdmin')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}
class GetOrderHistory extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Orders/GetOrderStatusHistories')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class DenyOrder extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Orders/DenyOrderWarrantyByOrderCode')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}
export {
  OrderChangeStatusRequest,
  GetOrderHistory,
  DenyOrder,
  OrderCreateRequest,
  OrderCreateByAdmin,
  OrderDeleteRequest,
  ChangeOrderBooster,
  OrderGetAllRequest,
  OrderCancelByAdminRequest,
  OrderGetByUserIdRequest,
  OrderGetProductsForStudents,
  OrderGetRegisteredUserRequest,
  OrderGetRequest,
  OrderCreateForAdminRequest,
  OrderUpdateRequest,
  OrderGetAllForBoostersRequest
}
