
import WebServiceRequest from '../Api/WebServiceRequest'

class VerifyOrder extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Orders/VerifyOrderWarranty')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}


class DenyOrder extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Orders/DenyOrderWarrantyByOrderCode')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}
class GetOrderWarranties extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('OrderWarranties/GetOrderWarranties')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetWarrantyState extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('OrderWarranties/GetOrderStates')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
export {
  VerifyOrder,
  GetOrderWarranties,
  GetWarrantyState,
  DenyOrder
}
