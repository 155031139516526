<template>
  <b-row>
    <b-col cols="12">
      <b-form @submit.prevent>
        <b-form-group label="Select User" label-for="user-picker">
          <v-select
              v-if="usersList"
              id="user-picker"
              v-model="selectedUser"
              :filterable="false"
              :options="usersList"
              :reduce="(user) => user.id"
              label="fullName"
              placeholder="Select Booster"
              @search="handleSearch"
          >
          </v-select>
        </b-form-group>
      </b-form>
    </b-col>
  </b-row>
</template>
<script>
import { BCol, BForm, BFormGroup, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { UsersGetAllRequest } from '@/libs/Api/Users'

export default {
  emits: ['getUser'],
  name:'CustomUserPicker',
  mounted() {
    this.getAllUsersList()
  },
  watch: {
    selectedUser: {
      handler(val) {
        this.$emit('getUser', val)
      },
    },
  },
  data() {
    return {
      usersList: null,
      selectedUser: null,
      searchVal: null,
      timeout: null,
    }
  },
  methods: {
    handleSearch(searchValue, loadingFn) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(async () => {
        this.searchVal = searchValue
        loadingFn(true)
        await this.getAllUsersList()
        loadingFn(false)
      }, 300)
    },
    async getAllUsersList() {
      let _this = this
      let data = {
        pageNumber: 1,
        count:10,
        search: _this.searchVal,
        type: 'BoosterUser',
      }

      let usersGetAllRequest = new UsersGetAllRequest(_this)
      usersGetAllRequest.setParams(data)
      await usersGetAllRequest.fetch(function (content) {
        _this.usersList = content.data.data
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
  },
}
</script>
