import { render, staticRenderFns } from "./OrderInfoSidebar.vue?vue&type=template&id=2c27f581&scoped=true&"
import script from "./OrderInfoSidebar.vue?vue&type=script&lang=js&"
export * from "./OrderInfoSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c27f581",
  null
  
)

export default component.exports