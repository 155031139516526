<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="order" class="row invoice-preview">
      <div class="col-md-8 col-xl-9 col-12">
        <div class="card invoice-preview-card"><!----><!---->
          <div class="card-body invoice-padding pb-0"><!----><!---->
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <div class="logo-wrapper d-flex flex-column align-items-start justify-content-center">
                  <div class="d-flex flex-column align-items-center justify-content-center">
                    <img src="../../../assets/images/logo/logo.png"
                         style="width: 70px;height: 70px;object-fit: contain"
                    />
                    <h3 class="text-primary invoice-logo my-1" data-v-7749c12c=""> Sky-Coach </h3>
                  </div>

                </div>
              </div>
              <div class="mt-md-0 mt-2" data-v-7749c12c=""><h4 class="invoice-title" data-v-7749c12c=""> Order : <span
                  class="invoice-number" data-v-7749c12c=""
              >{{ order.orderCode }}</span></h4>
                <div class="invoice-date-wrapper d-flex align-items-center" data-v-7749c12c=""><p
                    class="invoice-date-title" data-v-7749c12c=""
                >
                  Date Issued: </p>
                  <p class="invoice-date ml-1" data-v-7749c12c=""> {{
                      new Date(order.createdAt).toLocaleDateString('en-US')
                    }}-{{ new Date(order.createdAt).toLocaleTimeString('en-US') }} </p>
                </div>
                <div class="invoice-date-wrapper d-flex align-items-center" data-v-7749c12c=""><p
                    class="invoice-date-title mr-1" data-v-7749c12c=""
                >
                  status: </p>

                  <p>
                    <b-badge
                        v-if="order.orderStatus==='Frozen'"
                        pill
                        class="p-1"
                        variant="info"
                    >
                      In Warranty
                    </b-badge>
                    <b-badge
                        v-if="order.orderStatus==='Waiting'"
                        pill
                        class="p-1"
                        variant="secondary"
                    >
                      Waiting For payment
                    </b-badge>
                    <b-badge
                        v-if="order.orderStatus==='Payed'"
                        pill
                        class="p-1"
                        variant="light-success"
                    >
                      Waiting For Boosters To Assign
                    </b-badge>
                    <b-badge
                        v-if="order.orderStatus==='Doing'"
                        pill
                        class="p-1"
                        variant="success"
                    >
                      Booster Assigned
                    </b-badge>
                    <b-badge
                        v-if="order.orderStatus==='Done'"
                        pill
                        class="p-1"
                        variant="success"
                    >
                      Booster Completed Order
                    </b-badge>
                    <b-badge
                        v-if="order.orderStatus==='VerifyByAdmin'"
                        pill
                        class="p-1"
                        variant="success"
                    >
                      Verified
                    </b-badge>
                    <b-badge
                        v-if="order.orderStatus==='Cancel'"
                        pill
                        class="p-1"
                        variant="danger"
                    >
                      Waiting For Reassign
                    </b-badge>
                  </p>
                </div>
                <div class="invoice-date-wrapper d-flex align-items-center" data-v-7749c12c=""><p
                    class="invoice-date-title mr-1" data-v-7749c12c=""
                >
                  type: </p>
                  <p>

                    <b-badge
                        v-if="order.orderType==='Default'"
                        pill
                        class="p-1"
                        variant="primary"
                    >
                      default
                    </b-badge>
                    <b-badge
                        v-if="order.orderType==='Express'"
                        class="p-1"
                        pill
                        variant="light-danger"
                    >
                      Express
                    </b-badge>
                    <b-badge
                        v-if="order.orderType==='ForceMajor'"
                        class="p-1"
                        pill
                        variant="danger"
                    >
                      Force
                    </b-badge>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr class="invoice-spacing" data-v-7749c12c="">
          <div class="card-body invoice-padding pt-0" data-v-7749c12c=""><!----><!---->
            <div class="row invoice-spacing" data-v-7749c12c="">
              <div class="p-0 col-xl-6 col-12" data-v-7749c12c="">
                <h6 class="mb-2" data-v-7749c12c=""> Order For: </h6>
                <div class="d-flex align-items-center gap-1">
                  <h6 class="mb-25">User Name :</h6>
                  <b-link :to="{ name: 'apps-users-edit', params: { id: order.buyerId } }">
                    <h6 class="mb-25 text-primary" data-v-7749c12c=""> {{ order.userName }} </h6>
                  </b-link>
                </div>
                <div class="d-flex align-items-center gap-1">
                  <h6 class="mb-25">User Email :</h6>
                  <h6 class="mb-25 " data-v-7749c12c="">{{ order.buyerEmail }} </h6>
                </div>

              </div>
              <div class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-start col-xl-6 col-12" data-v-7749c12c="">
                <div data-v-7749c12c=""><h6 class="mb-2" data-v-7749c12c=""> Payment Details: </h6>
                  <table data-v-7749c12c="">
                    <tbody data-v-7749c12c="">
                    <tr data-v-7749c12c="">
                      <td class="pr-1" data-v-7749c12c=""> Total Due :</td>
                      <td data-v-7749c12c=""><span class="font-weight-bold" data-v-7749c12c=""
                      >{{
                          Intl.NumberFormat('en-US')
                              .format(order.totalPrice)
                        }} {{ order.currency }}</span></td>
                    </tr>
                    <tr data-v-7749c12c="">
                      <td class="pr-1" data-v-7749c12c=""> Total Due Payed:</td>
                      <td data-v-7749c12c=""><span class="font-weight-bold" data-v-7749c12c=""
                      >{{
                          Intl.NumberFormat('en-US')
                              .format(order.finalPrice)
                        }} {{ order.currency }}</span></td>
                    </tr>
                    <tr data-v-7749c12c="">
                      <td class="pr-1" data-v-7749c12c=""> Due Payed By Cashback:</td>
                      <td data-v-7749c12c=""><span class="font-weight-bold" data-v-7749c12c=""
                      >{{
                          Intl.NumberFormat('en-US')
                              .format(order.usedCashBackValue)
                        }} {{ order.currency }}</span></td>
                    </tr>

                    <tr data-v-7749c12c="">
                      <td class="pr-1" data-v-7749c12c=""> Booster Profit:</td>
                      <td data-v-7749c12c="">{{
                          Intl.NumberFormat('en-US')
                              .format(order.boosterPrice)
                        }} {{ order.currency }}
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" data-v-7749c12c="">
            <b-table
                ref="refUserListTable"
                :fields="myTableColumns"
                :items="order.orderItems"
                bordered
                class="position-relative"
                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                sort-by.sync
                striped
            >
              <template #cell(priceValue)="data">
                <span>   {{
                    Intl.NumberFormat('en-US')
                        .format(data.item.priceValue)
                  }} {{ order.currency }}
                </span>
              </template>
              <template #cell(adminDescription)="data">
                <span>   {{ order.adminDescription }}
                </span>
              </template>
            </b-table>

          </div>
          <div class="card-body invoice-padding pb-0" data-v-7749c12c=""><!----><!---->
            <div class="row" data-v-7749c12c="">
              <div v-if="order.boosterId" class="mt-md-0 mt-3 col-md-6 order-md-1 col-12 order-2" data-v-7749c12c="">
                <p class="card-text mb-0">
                  <span class="font-weight-bold" data-v-7749c12c="">Booster:</span>
                  <b-link :to="{ name: 'apps-users-edit', params: { id: order.boosterId } }">
                    <span class="ml-75 text-primary">{{ order.boosterUserName }}</span>
                  </b-link>
                </p>
                <p class="card-text mb-0">
                  <span class="font-weight-bold" data-v-7749c12c="">Booster Email:</span>
                  <span class="ml-75 ">{{ order.boosterEmail }}</span>
                </p>
              </div>
              <div v-else class="mt-md-0 mt-3 col-md-6 order-md-1 col-12 order-2" data-v-7749c12c="">
                <span>No Booster Assigned</span>
              </div>
              <div class="mt-md-6 d-flex justify-content-start col-md-6 order-md-2 col-12 order-1" data-v-7749c12c="">
                <div class="invoice-total-wrapper" data-v-7749c12c="">

                  <div class="invoice-total-item d-flex align-items-center" data-v-7749c12c="">
                    <p class="invoice-total-title mr-1" data-v-7749c12c="">Cashback Customer received: </p>
                    <p class="invoice-total-amount" data-v-7749c12c="">

                      {{
                        order.discountCashBack ? Intl.NumberFormat('en-US')
                            .format(order.discountCashBack) + ' ' + order.currency : 'none'
                      }} </p>

                  </div>
                  <!--                  <div class="invoice-total-item d-flex align-items-center" data-v-7749c12c="">-->
                  <!--                    <p class="invoice-total-title mr-1" data-v-7749c12c="">Discount: </p>-->
                  <!--                    <p class="invoice-total-amount" data-v-7749c12c="">-->
                  <!--                      {{ order.discountValue ? Intl.NumberFormat('en-US').format(order.discountValue) : 'none' }}</p>-->
                  <!--                  </div>-->
                  <div class="invoice-total-item d-flex align-items-center" data-v-7749c12c="">
                    <p class="invoice-total-title mr-1" data-v-7749c12c="">Coupon Discount: </p>
                    <p class="invoice-total-amount" data-v-7749c12c="">
                      {{
                        order.couponValue ? Intl.NumberFormat('en-US')
                            .format(order.couponValue) + `${order.currency}` : 'none'
                      }} </p>
                  </div>
                  <div class="invoice-total-item d-flex align-items-center" data-v-7749c12c="">
                    <p class="invoice-total-title mr-1" data-v-7749c12c="">Maximum Booster Profit: </p>
                    <p class="invoice-total-amount" data-v-7749c12c="">{{
                        Intl.NumberFormat('en-US')
                            .format(order.maxBoosterPrice)
                      }} {{ order.currency }}</p>
                  </div>
                  <div class="invoice-total-item d-flex align-items-center" data-v-7749c12c="">
                    <p class="invoice-total-title mr-1" data-v-7749c12c="">Quantity: </p>
                    <p class="invoice-total-amount" data-v-7749c12c="">{{
                        order.quantity
                      }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="invoice-spacing" data-v-7749c12c="">
          <div class="card-body invoice-padding pt-0" data-v-7749c12c="">
            <b-alert v-if="order.orderImages.length===0" class="p-1" show variant="warning">
              <span class="font-weight-bold">Note: </span><span>Booster Pictures Of Proof For completing The Tasks Will Appear Here</span>
            </b-alert>
            <OrderMediaHolder v-else :items="order.orderImages"></OrderMediaHolder>
          </div>
        </div>
      </div>
      <OrderInfoSidebar :order="order" @refetch="getOrder" @setEdit="openEditModal"></OrderInfoSidebar>
    </div>
    <b-card>
      <b-row>
        <b-col class="mb-1" cols="12">
          <strong class="">History</strong>
        </b-col>
        <b-col cols="12">
          <b-table
              ref="refUserListTable"
              :fields="myTableColumns2"
              :items="orderHistory"
              bordered
              class="position-relative"
              empty-text="Nothing found !"
              primary-key="id"
              responsive
              show-empty
              striped
          >
            <template #cell(statusFrom)="data">
              <p>
                <b-badge
                    v-if="data.item.statusFrom==='Frozen'"
                    pill
                    variant="info"
                    class="p-1"
                >
                  In Warranty
                </b-badge>
                <b-badge
                    v-if="data.item.statusFrom==='Waiting'"
                    pill
                    variant="secondary"
                    class="p-1"
                >
                  Waiting For payment
                </b-badge>
                <b-badge
                    v-if="data.item.statusFrom==='Payed'"
                    pill
                    variant="light-success"
                    class="p-1"
                >
                  Waiting For Boosters To Assign
                </b-badge>
                <b-badge
                    v-if="data.item.statusFrom==='Doing'"
                    pill
                    variant="success"
                    class="p-1"
                >
                  Booster Assigned
                </b-badge>
                <b-badge
                    v-if="data.item.statusFrom==='Done'"
                    pill
                    variant="success"
                    class="p-1"
                >
                  Booster Completed Order
                </b-badge>
                <b-badge
                    v-if="data.item.statusFrom==='Cancel'"
                    pill
                    variant="danger"
                    class="p-1"
                >
                  Waiting For Reassign
                </b-badge>
              </p>

            </template>
            <template #cell(statusTo)="data">
              <p>
                <b-badge
                    v-if="data.item.statusTo==='Frozen'"
                    pill
                    variant="info"
                    class="p-1"
                >
                  In Warranty
                </b-badge>
                <b-badge
                    v-if="data.item.statusTo==='Waiting'"
                    pill
                    variant="secondary"
                    class="p-1"
                >
                  Waiting For payment
                </b-badge>
                <b-badge
                    v-if="data.item.statusTo==='Payed'"
                    pill
                    variant="light-success"
                    class="p-1"
                >
                  Waiting For Boosters To Assign
                </b-badge>
                <b-badge
                    v-if="data.item.statusTo==='Doing'"
                    pill
                    variant="success"
                    class="p-1"
                >
                  Booster Assigned
                </b-badge>
                <b-badge
                    v-if="data.item.statusTo==='Done'"
                    pill
                    variant="success"
                    class="p-1"
                >
                  Booster Completed Order
                </b-badge>
                <b-badge
                    v-if="data.item.statusTo==='Cancel'"
                    pill
                    class="p-1"
                    variant="danger"
                >
                  Waiting For Reassign
                </b-badge>
              </p>

            </template>
            <template #cell(createdAt)="data">
              <span>{{
                  new Date(data.item.createdAt).toLocaleDateString('en-US')
                }}-{{ new Date(data.item.createdAt).toLocaleTimeString('en-US') }}</span>
            </template>

          </b-table>
          <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalCount"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
        id="modal-update"
        cancelTitle="Cancel"
        centered
        title="Edit Order"
        ok-title="Update"
        @ok="updateOrder"
    >
      <b-row v-if="selectedOrder">
        <b-col cols="12">
          <b-form-group
              :label="`Booster Price-current currency : (${selectedOrder.currency})`"
          >
            <b-form-input
                v-model="selectedOrder.boosterPrice"
                type="number"

            />
          </b-form-group>
        </b-col>
        <b-col
            v-if="order.orderStatus==='Frozen'"
            cols="12"
        >
          <b-form-group
              class="mb-2 mt-25"
              label="Status"
          >
            <v-select
                v-model="selectedOrder.orderStatus"
                :clearable="false"
                :options="orderStatuses"
                :reduce="label=> label.key"
                class="per-page-selector w-100 d-inline-block "
                label="label"
            />

          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              class="mb-2 mt-25"
              label="Type"
          >
            <v-select
                v-model="selectedOrder.orderType"
                :clearable="false"
                :options="orderTypes"
                :reduce="name=> name.value"
                class="per-page-selector w-100 d-inline-block "
                label="name"
            />

          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="extra description"
          >
            <b-form-input
                v-model="selectedOrder.adminDescription"

            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BAlert,
  BBadge,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { GetOrderHistory, OrderGetRequest, OrderUpdateRequest } from '@/libs/Api/Order'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Debouncer from '@/views/components/debouncer.vue'
import OrderInfoSidebar from '@/views/components/Order/OrderInfoSidebar.vue'
import OrderMediaHolder from '@/views/components/Order/OrderMediaHolder.vue'

export default {
  name: 'OrdersList',
  title: 'Orders List',
  data() {
    return {
      order: null,
      orderStatuses: [
        {
          key: 'Waiting',
          label: 'Waiting For payment',
          variant: 'secondary'
        },
        {
          key: 'Payed',
          label: 'Waiting For Boosters To Assign',
          variant: 'light-success'
        },
        {
          key: 'Doing',
          label: 'Booster Assigned',
          variant: 'success'
        },
        {
          key: 'Done',
          label: 'Booster Completed Order',
          variant: 'success'
        },
        {
          key: 'Cancel',
          label: 'Waiting For Reassign',
          variant: 'danger'
        },
      ],
      orderTypes: [
        {
          name: 'Default',
          value: 'Default'
        },
        {
          name: 'Express',
          value: 'Express'
        },
        {
          name: 'Force',
          value: 'ForceMajor'
        },
      ],
      overlay: false,
      selectedOrder: null,
      myTableColumns: [
        {
          key: 'orderItemId',
          label: 'Task Id',
        },
        {
          key: 'description',
          label: 'task description',
        },
        {
          key: 'adminDescription',
          label: 'extras',
        },
        {
          key: 'priceValue',
          label: 'price',
        },

      ],
      myTableColumns2: [
        {
          key: 'changeByUserName',
          label: 'Changed By',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Date',
          sortable: true
        },
        {
          key: 'reason',
          label: 'reason',
          sortable: true
        },
        {
          key: 'statusFrom',
          label: 'From',
          sortable: true
        },
        {
          key: 'statusTo',
          label: 'To',
          sortable: true
        },
      ],
      orderHistory: null,
      perPage: 3,
      currentPage: 1,
      totalCount: null,
    }
  },
  async created() {
    await Promise.all([
      this.getOrder(),
    ])
  },

  methods: {
    async getOrderHistory() {
      let _this = this
      _this.overlay = true

      let getOrderHistory = new GetOrderHistory(_this)
      let data = {
        pageNumber: _this.currentPage,
        orderCode: _this.order.orderCode,
        count: _this.perPage
      }
      getOrderHistory.setParams(data)
      await getOrderHistory.fetch(function (content) {
        _this.overlay = false
        _this.orderHistory = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    setSelectedOrder() {
      this.selectedOrder = JSON.parse(JSON.stringify(this.order))
    },
    async getOrder() {
      let _this = this
      _this.overlay = true

      let orderGetRequest = new OrderGetRequest(_this)
      orderGetRequest.setParams({ id: _this.$route.params.id })
      await orderGetRequest.fetch(function (content) {
        _this.overlay = false
        _this.order = content.data
        _this.getOrderHistory()

      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    async openEditModal() {
      this.selectedOrder = this.order
      this.$bvModal.show('modal-update')
    },
    async updateOrder() {
      let _this = this
      _this.overlay = true
      let orderChangeStatusRequest = new OrderUpdateRequest(_this)
      _this.selectedOrder['orderId'] = _this.order.id
      orderChangeStatusRequest.setPayload(_this.selectedOrder)
      await orderChangeStatusRequest.fetch(function (content) {
        _this.overlay = false
        if (content.isSuccess) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Done`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          _this.getOrder()
        } else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: content.message,
              title: `error`,
              variant: 'danger',
            },
          })
        }
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })

    },

  },
  watch: {
    currentPage: function (val) {
      this.getOrderHistory()
    }
  },
  components: {
    OrderMediaHolder,
    OrderInfoSidebar,
    Debouncer,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BAlert,
    BLink,
    BBadge,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
