<template>
  <div class="d-flex align-items-center">
    <b-modal
        id="modal-media"
        ok-only
        title="View Pictures"
        centered
    >
      <b-row v-if="selectedImage" >
        <b-col cols="12">
          <img :src="selectedImage.imageUrl" style="width: 100%;max-height: 60rem;object-fit: contain" alt="">
        </b-col>
      </b-row>
    </b-modal>

    <div v-for="(item,idx) in items" class="">
      <div
          v-b-modal.modal-media
          class=" position-relative mx-1 cursor-pointer  border rounded  shadow     "
          style="height: 10rem;width: 10rem"
          @click="setSelectedImage(item)"
      >

        <img :src="item.imageUrl" alt="" style="object-fit: contain;height: 10rem;width: 10rem">
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'OrderMediaHolder',
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {
      selectedImage: null,
    }
  },
  methods: {
    async setSelectedImage(item) {
      this.selectedImage = JSON.parse(JSON.stringify(item))
    },
  }
}
</script>

<style scoped>

</style>
